import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import {createHttpLink} from "apollo-link-http";

const cache = new InMemoryCache();

const apiUrl = process.env.REACT_APP_API;

const link = new createHttpLink({
    uri: apiUrl,
    credentials: 'include'
});

const client = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
});

export default client;
